<template>
  <div class="px-8 py-12">
    <card :title="$t('integrators.title')" class="text-gray-500">
      <div class="mb-6 -mt-5">
        <l-input
          v-model="query"
          class="max-w-lg"
          icon="search"
          icon-class="text-blue"
        />
      </div>
      <div class="flex flex-row border-b border-gray-200 text-sm pb-2">
        <div class="w-2/12">{{ $t('integrators.name') }}</div>
        <div class="w-5/12">{{ $t('integrators.token') }}</div>
        <div class="w-2/12">{{ $t('integrators.creationTime') }}</div>
        <div class="w-2/12">{{ $t('integrators.production') }}</div>
        <div class="w-1/12">{{ $t('integrators.moreDetails') }}</div>
      </div>
      <div
        v-for="(integrator, index) of filteredIntegrators"
        :key="index"
        class="mb-10 flex"
      >
        <div class="mt-5 w-2/12">{{ integrator.name }}</div>
        <div class="mt-5 w-5/12">{{ integrator.token }}</div>
        <div class="mt-5 w-2/12">
          {{ formattedDate(integrator.creationTime) }}
        </div>
        <div class="mt-5 w-2/12">{{ integrator.production }}</div>
        <div class="mt-5 w-1/12">
          <icon
            name="eye"
            class="w-6 h-6 text-violet cursor-pointer"
            @click="goToAdmin(integrator.userId)"
          />
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '@last/core-ui/components/LCard.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import lastUtils from '@last/core/src/lastUtils'
import { mapState } from 'vuex'
import api from '@/api'
import moment from 'moment'

export default {
  name: 'Integrators',
  components: {
    Card,
    Icon,
    LInput
  },
  props: {},
  data() {
    return {
      query: '',
      integrators: []
    }
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    filteredIntegrators() {
      if (!this.query) return this.integrators
      return this.integrators.filter(integrator => {
        return lastUtils.fuzzy(integrator.name, this.query)
      })
    }
  },
  async mounted() {
    const response = await api.get('/support/integrators')
    this.integrators = response.data
  },
  methods: {
    goToAdmin(userId) {
      window.open(
        `${process.env.VUE_APP_LAST_DEVELOPER_URL}/support/login?supportToken=${this.accessToken}&userId=${userId}&tester=true`
      )
    },
    formattedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
